<template>
  <!--内容-->
  <div class="page-content-x">
    <!--搜索-->
    <div v-show="issearch" class="page-content-search">
      <el-form
        ref="form"
        :model="search"
        size="medium"
        :label-width="this.env.search_label_width"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="昵称">
              <el-input v-model="search.user_nick" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="search.phone" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="page-content-search-button">
          <el-col :span="24">
            <el-form-item>
              <el-button
                icon="el-icon-search"
                size="medium"
                type="primary"
                @click="is_search"
                >搜索
              </el-button>
              <el-button icon="el-icon-circle-close" size="medium" @click="init"
                >清空搜索</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--标题-->
    <el-row>
      <el-col :span="12"
        ><h3>{{ page_name }}</h3></el-col
      >
      <el-col :span="12">
        <div class="top-operation-button">
          <el-button
            v-if="issearch"
            icon="el-icon-caret-top"
            size="medium"
            type="primary"
            @click="issearch = !issearch"
            >隐藏搜索
          </el-button>
          <el-button
            v-else
            icon="el-icon-caret-bottom"
            size="medium"
            type="primary"
            @click="issearch = !issearch"
            >显示搜索
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div style="height: 20px;"></div>
    <!--列表-->
    <el-table
      size="medium"
      border
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="avatar_url" label="头像" width="60">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            :size="22"
            :src="scope.row.avatar_url"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="user_nick" label="用户昵称" />
      <el-table-column prop="phone" label="用户手机号"> </el-table-column>
      <!-- <el-table-column prop="lesson_duration" label="是否关注小程序">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.is_subscribe === 1"
            >已关注</el-tag
          >
          <el-tag size="mini" v-if="scope.row.is_subscribe === 0" type="danger"
            >未关注</el-tag
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="create_time" label="关注时间"> </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.isCheck"
            @click="selectUser(scope.row.user_uuid, scope.row.user_nick)"
            size="mini"
            >选择
          </el-button>
          <el-button
            v-else
            @click="deletUser(scope.row.user_uuid, scope.row.user_nick)"
            type="danger"
            size="mini"
            >已选择
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 20px;"></div>
    <!--分页-->
    <el-pagination
      @current-change="getlist"
      :page-size="this.env.pageSize"
      :pager-count="7"
      background
      layout="prev, pager, next, total"
      :current-page.sync="page"
      :total="count"
    >
    </el-pagination>
    <el-row style="margin: 10px 0px;">
      <el-button size="medium" type="primary" @click="save">确 定 </el-button>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "选择用户",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      userIdArr: [], // 用户id数组
      nameArr: [], // 用户名字展示数组
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 初始化
    init() {
      this.search = {
        lesson_name: "",
        publish_state: "",
        lesson_tag_s: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      const { userIdArr } = this;
      let postdata = {
        api_name: "user.member.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          const tableData = json.data.list;
          for (let i in tableData) {
            if (userIdArr.length > 0) {
              for (let j in userIdArr) {
                if (tableData[i].user_uuid === userIdArr[j]) {
                  tableData[i].isCheck = true;
                  break;
                } else {
                  tableData[i].isCheck = false;
                }
              }
            } else {
              tableData[i].isCheck = false;
            }
          }
          this.tableData = tableData;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 添加用户
    selectUser(id, name) {
      const { userIdArr, nameArr, tableData } = this;
      userIdArr.push(id);
      nameArr.push(name);
      this.nameArr = nameArr;
      this.userIdArr = userIdArr;
      for (let i in tableData) {
        if (tableData[i].user_uuid === id) {
          tableData[i].isCheck = true;
        }
      }
      this.tableData = tableData;
      this.$emit("returnUserInfo", nameArr, userIdArr);
    },
    // 删除用户
    deletUser(id, name) {
      const { userIdArr, nameArr, tableData } = this;
      const idIndex = userIdArr.indexOf(id),
        nameIndex = nameArr.indexOf(name);
      userIdArr.splice(idIndex, 1);
      nameArr.splice(nameIndex, 1);
      for (let i in tableData) {
        if (tableData[i].user_uuid === id) {
          tableData[i].isCheck = false;
        }
      }
      this.userIdArr = userIdArr;
      this.nameArr = nameArr;
      this.tableData = tableData;
      this.$emit("returnUserInfo", nameArr, userIdArr);
    },
    delet(index) {
      const { nameArr, userIdArr, tableData } = this;
      for (let i in tableData) {
        if (tableData[i].user_uuid === userIdArr[index]) {
          tableData[i].isCheck = false;
        }
      }
      nameArr.splice(index, 1);
      userIdArr.splice(index, 1);
      this.nameArr = nameArr;
      this.userIdArr = userIdArr;
      this.tableData = tableData;
      this.$emit("returnUserInfo", nameArr, userIdArr);
    },
    save() {
      this.$emit("closeUserModel");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
