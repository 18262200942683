<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/mpwx/templatemsglist' }"
          >消息模板列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>发送消息模板</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
      </el-row>
      <el-form
        ref="form"
        :model="form"
        size="medium"
        :label-width="this.env.label_width"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="16">
            <el-form-item label="用户">
              <el-button
                v-if="is_auth('user.member.getlist')"
                type="primary"
                @click="template()"
                >选择用户</el-button
              >
              <div style="margin-left:10px;display:inline-block">
                <el-checkbox v-model="isAll">全部用户</el-checkbox>
              </div>
            </el-form-item>
            <el-form-item v-if="userNameArr.length > 0" label="已选用户">
              <div class="form-tag_colour-div">
                <el-tag
                  v-for="(item, key) in this.userNameArr"
                  :key="key"
                  class="form-tag_colour-tag"
                  effect="dark"
                  closable
                  size="small"
                  @close="deletUserName(key)"
                >
                  {{ item }}
                </el-tag>
              </div>
            </el-form-item>

            <el-form-item label="兴趣标签">
              <div>
                <el-select
                  @change="add_like_tag"
                  v-model="select_like_tag"
                  filterable
                  placeholder="请选择标签"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in like_tag_list"
                    :key="item.id"
                    :label="item.title"
                    :value="`${item.title}|${item.id}`"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="form-tag_colour-div">
                <el-tag
                  v-for="(item, key) in this.selectTagArr"
                  :key="key"
                  class="form-tag_colour-tag"
                  effect="dark"
                  closable
                  size="small"
                  @close="tagClose(item, 'like_s')"
                >
                  {{ item.title }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="小程序跳转地址">
              <el-input
                style="width:100%"
                placeholder="请输入需要跳转的小程序地址"
                v-model="form.to_url"
              />
            </el-form-item>
            <!-- <el-form-item label="消息模板">
              <el-select
                v-model="form.region"
                placeholder="请选择活动区域"
                style="width: 90%"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="用户">
              <div class="box">
                <div class="box-content-border">
                  <div class="box-content">
                    <div class="content-title">标题</div>
                    <el-input
                      v-model="form.first_value"
                      style="width:60%"
                      placeholder="请输入模板标题"
                    ></el-input>
                  </div>
                  <div class="box-content">
                    <div class="content-title">服务名称</div>
                    <el-input
                      v-model="form.data_name"
                      style="width:60%"
                      placeholder="请输入模板服务名称"
                    ></el-input>
                  </div>
                  <div class="box-content">
                    <div class="content-title">生效时间</div>
                    <el-date-picker
                      v-model="form.data_time"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="不选则默认当天"
                    >
                    </el-date-picker>
                  </div>
                  <div class="box-content">
                    <div class="content-title">服务内容</div>
                    <el-input
                      v-model="form.data_text"
                      style="width:60%"
                      placeholder="请输入模板服务内容"
                    ></el-input>
                  </div>
                  <div class="box-content">
                    <div class="content-title">备注</div>
                    <el-input
                      v-model="form.remark_value"
                      style="width:60%"
                      placeholder="请输入模板备注"
                    ></el-input>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-button
                    v-if="is_auth('wxmp.template.sendtemplate')"
                    size="primary"
                    type="primary"
                    @click="submit"
                    >发送</el-button
                  >
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-dialog
        title="选择用户"
        :visible.sync="template_page"
        width="70%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-row>
          <Template
            ref="userModel"
            v-on:template_select="template_select"
            @returnUserInfo="returnUserInfo"
            @closeUserModel="closeUserModel"
          />
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Template from "./Template";
export default {
  components: {
    Template,
  },
  data() {
    return {
      loading: false, // 加载状态
      page_name: "发送消息模板",
      select_like_tag: "", // 选择的门店标签
      template_page: false, // 模板显示隐藏
      like_tag_list: [], // 门店标签
      userNameArr: [], // 选择用户数组
      selectTagArr: [],
      userIdArr: [],
      isAll: false,
      form: {
        like_s: [],
      },
    };
  },
  // 创建
  created() {
    this.getTags();
  },
  // 安装
  mounted() {},
  methods: {
    // 获取兴趣标签
    getTags() {
      const postData = {
        api_name: "wxmp.template.getuserlikes",
        token: this.Tool.get_l_cache("token"),
      };
      this.Tool.post_data("oss", postData, (res) => {
        if (res.code === 0) {
          this.like_tag_list = res.data;
        }
      });
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 打开模板
    template() {
      this.template_page = true;
    },
    // 模板选择
    template_select(data) {
      // console.log('data', data)
      this.form = data;
      this.template_page = false;
    },
    // 添加门店标签
    add_like_tag() {
      let select_tag = this.select_like_tag.split("|");
      let tag = {
        title: select_tag[0],
        id: select_tag[1],
      };
      console.log(tag);
      if (!this.tag_exist(tag.title, "like_s")) {
        this.form.like_s.push(tag.id);
        this.selectTagArr.push(tag);
      }
      this.select_like_tag = "";
    },
    // 判断标签是否存在
    tag_exist(tag_name, key) {
      if (this.form[key].length > 0) {
        for (let v of this.form[key]) {
          if (tag_name == v.tag_name) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(tag, key) {
      this.selectTagArr.splice(this.selectTagArr.indexOf(tag), 1);
      this.form[key].splice(this.form[key].indexOf(tag.id), 1);
    },
    returnUserInfo(nameArr, idArr) {
      this.userNameArr = nameArr;
      this.userIdArr = idArr;
    },
    closeUserModel() {
      this.template_page = false;
    },
    // 删除所选择的用户
    deletUserName(index) {
      this.$refs.userModel.delet(index);
    },
    // 发送
    submit() {
      let { userIdArr, form, isAll } = this;
      if (isAll) {
        form.like_s = ["all"];
      }
      this.loading = true;
      if (userIdArr.length === 0 && form.like_s.length === 0) {
        this.$message({
          showClose: true,
          message: "请选择需要发送的用户或者标签",
          type: "error",
        });
      } else {
        const postdata = {
          api_name: "wxmp.template.sendtemplate",
          token: this.Tool.get_l_cache("token"),
          user_uuid: userIdArr,
        };
        Object.assign(postdata, this.form);
        this.Tool.post_data("oss", postdata, (res) => {
          this.loading = false;
          if (res.code === 0) {
            this.$message({
              message: "发送成功！",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({ path: "/mpwx/templatemsglist" });
            }, 500);
          } else {
            this.Tool.errormes(res);
          }
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  border: 1px solid #40a9ff;
}

.box-content {
  display: flex;
  padding: 10px;
}

.box-content-border {
  padding: 10px;
}

.content-title {
  width: 20%;
  padding-right: 20px;
  text-align: center;
}
</style>
